import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import '@/styles/reset.scss'
import 'lib-flexible' // 引入适配包
import '@/styles/animationH5.css'
// 导入 NProgress 包对应的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import axios from 'axios'
// 配置请求的跟路径
// 本地
// axios.defaults.baseURL = 'https://172.16.40.251/'
// 线上
axios.defaults.baseURL = 'https://api.juquedu.com/'
// 在 request 拦截器中，展示进度条 NProgress.start()
axios.interceptors.request.use((config) => {
	// console.log(config)
	NProgress.start()
	config.headers.Authorization = window.sessionStorage.getItem('token')
	// 在最后必须 return config
	return config
})
// 在 response 拦截器中，隐藏进度条 NProgress.done()
axios.interceptors.response.use((config) => {
	NProgress.done()
	return config
})
Vue.prototype.$http = axios

// import Vconsole from "vconsole";
// new Vconsole();
Vue.config.productionTip = false

new Vue({
	router,
	render: (h) => h(App)
}).$mount('#app')
