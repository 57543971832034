import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	// mode: 'history',
	routes: [
		{ path: '/', redirect: '/index' },
		{
			path: '/index',
			name: 'index',
			component: () => import('./views/index.vue'),
		},
		{
			path: '/mobile',
			name: 'mobile',
			component: () => import('./views/mobile.vue'),
		},

		{
			path: '/404',
			name: 'notFound',
			component: () => import('./views/404notFound.vue'),
		},
		{
			path: '*', // 此处需特别注意置于最底部
			redirect: '/404',
		},
	],
})
