<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'app',
	methods: {
		// 添加判断方法
		isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		},
	},
	mounted() {
		if (this.isMobile()) {
			// alert("手机端");
			this.$router.replace({ name: 'mobile' })
		} else {
			// alert("pc端");
			this.$router.replace({ name: 'index' })
		}
	},
}
</script>

<style lang="scss">
body {
	background-color: #fff;
}
</style>
